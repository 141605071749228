// Code generated by Slice Machine. DO NOT EDIT.

import { defineAsyncComponent } from "vue";
import { defineSliceZoneComponents } from "@prismicio/vue";

export const components = defineSliceZoneComponents({
  autoscroll_gallery: defineAsyncComponent(
    () => import("./AutoscrollGallery/index.vue"),
  ),
  benefits_panel: defineAsyncComponent(
    () => import("./BenefitsPanel/index.vue"),
  ),
  big_image: defineAsyncComponent(() => import("./BigImage/index.vue")),
  big_image_with_cta: defineAsyncComponent(
    () => import("./BigImageWithCta/index.vue"),
  ),
  big_title_hero: defineAsyncComponent(
    () => import("./BigTitleHero/index.vue"),
  ),
  care_products: defineAsyncComponent(
    () => import("./CareProductsGrid/index.vue"),
  ),
  categories_layout: defineAsyncComponent(
    () => import("./CategoriesLayout/index.vue"),
  ),
  category_collections: defineAsyncComponent(
    () => import("./CategoryCollections/index.vue"),
  ),
  category_hero: defineAsyncComponent(() => import("./CategoryHero/index.vue")),
  category_list_homepage: defineAsyncComponent(
    () => import("./CategoryListHomepage/index.vue"),
  ),
  category_list_homepage_mobile: defineAsyncComponent(
    () => import("./CategoryListHomepageMobile/index.vue"),
  ),
  collaborate_cta: defineAsyncComponent(
    () => import("./CollaborateCta/index.vue"),
  ),
  collaborations_homepage: defineAsyncComponent(
    () => import("./CollaborationsHomepage/index.vue"),
  ),
  collection_hero: defineAsyncComponent(
    () => import("./CollectionHero/index.vue"),
  ),
  collection_introduction: defineAsyncComponent(
    () => import("./CollectionIntroduction/index.vue"),
  ),
  collection_showcase: defineAsyncComponent(
    () => import("./CollectionShowcase/index.vue"),
  ),
  coming_soon: defineAsyncComponent(() => import("./ComingSoon/index.vue")),
  content_with_button: defineAsyncComponent(
    () => import("./ContentWithButton/index.vue"),
  ),
  design_council: defineAsyncComponent(
    () => import("./DesignCouncil/index.vue"),
  ),
  design_services_homepage: defineAsyncComponent(
    () => import("./DesignServicesHomepage/index.vue"),
  ),
  designer_collaboration: defineAsyncComponent(
    () => import("./DesignerCollaboration/index.vue"),
  ),
  editorial_layout: defineAsyncComponent(
    () => import("./EditorialLayout/index.vue"),
  ),
  faq: defineAsyncComponent(() => import("./Faq/index.vue")),
  featured_designer: defineAsyncComponent(
    () => import("./FeaturedDesigner/index.vue"),
  ),
  flagship: defineAsyncComponent(() => import("./Flagship/index.vue")),
  footer_next_page: defineAsyncComponent(
    () => import("./FooterNextPage/index.vue"),
  ),
  gallery_numbered: defineAsyncComponent(
    () => import("./GalleryNumbered/index.vue"),
  ),
  hero_centered: defineAsyncComponent(() => import("./HeroCentered/index.vue")),
  homepage_hero: defineAsyncComponent(() => import("./HomepageHero/index.vue")),
  image_and_quote: defineAsyncComponent(
    () => import("./ImageAndQuote/index.vue"),
  ),
  image_with_cta: defineAsyncComponent(
    () => import("./ImageWithCta/index.vue"),
  ),
  images_aside: defineAsyncComponent(() => import("./ImagesAside/index.vue")),
  informations_panel: defineAsyncComponent(
    () => import("./InformationsPanel/index.vue"),
  ),
  inspiration: defineAsyncComponent(() => import("./Inspiration/index.vue")),
  jobs_panel: defineAsyncComponent(() => import("./JobsPanel/index.vue")),
  manufacturers: defineAsyncComponent(
    () => import("./Manufacturers/index.vue"),
  ),
  maps: defineAsyncComponent(() => import("./Maps/index.vue")),
  more_for_yourhome: defineAsyncComponent(
    () => import("./MoreForYourHome/index.vue"),
  ),
  page_hero: defineAsyncComponent(() => import("./PageHero/index.vue")),
  page_introduction: defineAsyncComponent(
    () => import("./PageIntroduction/index.vue"),
  ),
  process: defineAsyncComponent(() => import("./Process/index.vue")),
  product_hero: defineAsyncComponent(() => import("./ProductHero/index.vue")),
  products_grid: defineAsyncComponent(() => import("./ProductsGrid/index.vue")),
  products_grid_full: defineAsyncComponent(
    () => import("./ProductsGridFull/index.vue"),
  ),
  quote: defineAsyncComponent(() => import("./Quote/index.vue")),
  scrollable_list: defineAsyncComponent(
    () => import("./ScrollableList/index.vue"),
  ),
  services_list: defineAsyncComponent(() => import("./ServicesList/index.vue")),
  share_your_project: defineAsyncComponent(
    () => import("./EditorialLayout2/index.vue"),
  ),
  social_networks: defineAsyncComponent(
    () => import("./SocialNetworks/index.vue"),
  ),
  special_category: defineAsyncComponent(
    () => import("./SpecialCategory/index.vue"),
  ),
  star_products: defineAsyncComponent(
    () => import("./FeaturedCollections/index.vue"),
  ),
  tailored_space: defineAsyncComponent(
    () => import("./TailoredSpace/index.vue"),
  ),
  tailored_space_story: defineAsyncComponent(
    () => import("./TailoredSpaceStory/index.vue"),
  ),
  title_copy_image: defineAsyncComponent(
    () => import("./TitleCopyImage/index.vue"),
  ),
  two_images_with_title: defineAsyncComponent(
    () => import("./TwoAssetsWithCaptions/index.vue"),
  ),
});
